import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PrivateParking = () => {
    return (
        <Layout>
            <Seo
                title="Parkmöglichkeiten Für Zuhause - BIKESAFE"
                description=" Organisieren Sie Ihre Fahrräder effizient und machen Sie das Beste aus ungenutzten Flächen. Unsere Fahrradständer für zuhause schützen Ihr Fahrrad, Ihre Wände und Ihre Fahrzeuge. Ganz gleich, ob Sie eine Fahrradunterbringung für Ihr Haus, Ihre Garage oder Ihren Garten suchen – wir haben eine passende Lösung. Nutzen Sie Ihre freien Flächen auf dem Fußboden oder an der Wand mit unseren Anlehnbügeln, Fahrradständern, Fahrradparkern, Fahrradwandhaltern, Fahrrad-Montageständern oder Fahrrad-Schränke optimal aus."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Parkmöglichkeiten Für Zuhause
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h5>Organisieren Sie Ihre Fahrräder effizient!</h5>
                        <p className="flow-text">
                            Organisieren Sie Ihre Fahrräder effizient und machen Sie das Beste aus ungenutzten Flächen. Unsere Fahrradständer für zuhause schützen Ihr Fahrrad, Ihre Wände und Ihre Fahrzeuge. Ganz gleich, ob Sie eine Fahrradunterbringung für Ihr Haus, Ihre Garage oder Ihren Garten suchen – wir haben eine passende Lösung. Nutzen Sie Ihre freien Flächen auf dem Fußboden oder an der Wand mit unseren Anlehnbügeln, Fahrradständern, Fahrradparkern, Fahrradwandhaltern, Fahrrad-Montageständern oder Fahrrad-Schränke optimal aus.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/sheffield.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Wir waren wirklich sehr zufrieden mit dem freundlichen und effizienten Service von Bike Safe. Sie haben innerhalb von wenigen Tagen, nachdem wir uns mit Ihnen in Verbindung gesetzt hatten, einen Fahrradstützbügel in unserem Vorgarten installiert. Der Ständer sieht großartig aus und bietet einen praktischen Ort für die sichere Aufbewahrung unserer Fahrräder, ohne dass wir dazu den Hausflur voll stellen müssen."
                                <strong> Emlyn Lamburn, Hausbesitzer, London.</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PrivateParking;